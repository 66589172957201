import React, { useState } from "react";
import PaginationComponent from "./Pagination";

const PermissionPopup = ({
  user,
  categoriesData,
  existingPermissions,
  onClose,
  onUpdatePermissions,
}) => {
  const [checkedPermissions, setCheckedPermissions] =
    useState(existingPermissions);
  const [categories, setCategories] = useState(categoriesData);
 const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); 
  const handleCheckboxChange = (category_id) => {
    const updatedPermissions = [...checkedPermissions];

    if (checkedPermissions.includes(category_id)) {
      // Uncheck the category and its children
      uncheckCategoryAndChildren(category_id, updatedPermissions);
    } else {
      // Check the category and its parents if needed
      checkCategoryAndParents(category_id, updatedPermissions);
    }

    setCheckedPermissions(updatedPermissions);
  };

  const handleSavePermissions = () => {
    onUpdatePermissions(checkedPermissions);
    onClose();
  };

  const handleToggle = (category_id) => {
    const updatedCategories = categories.map((category) => {
      if (category.category_id === category_id) {
        return {
          ...category,
          expanded: !category.expanded,
        };
      }
      return category;
    });

    setCategories(updatedCategories);
  };

  const checkCategoryAndParents = (category_id, updatedPermissions) => {
    // Check the category
    let updatedCategories = [];
    if (category_id?.length === 2) {
      const childCategory = findChildCategories(category_id);
      if (Array.isArray(childCategory)) {
        updatedCategories.push(category_id, ...childCategory);
      } else {
        updatedCategories.push(category_id, childCategory);
      }
    } else {
      const parentCategory = findParentCategory(category_id);
      if (Array.isArray(parentCategory)) {
        updatedCategories.push(category_id, ...parentCategory);
      } else {
        updatedCategories.push(category_id, parentCategory);
      }
    }

    if (Array.isArray(updatedCategories)) {
      for (const childCategory of updatedCategories) {
        const index = updatedPermissions.indexOf(childCategory);
        if (index == -1) {
          updatedPermissions.push(childCategory);
        }
      }
    } else if (!updatedPermissions.includes(updatedCategories)) {
      updatedPermissions.push(updatedCategories);
    }
  };

  const uncheckCategoryAndChildren = (category_id, updatedPermissions) => {
    updatedPermissions.splice(updatedPermissions.indexOf(category_id), 1);

    // Find the children categories
    if (category_id?.length === 2) {
      const childCategories = findChildCategories(category_id);

      // Uncheck the children categories
      for (const childCategory of childCategories) {
        const index = updatedPermissions.indexOf(childCategory);
        if (index !== -1) {
          updatedPermissions.splice(index, 1);
        }
      }
    }
    // else {
    //   const parentCategories = findParentCategory(category_id);
    //   for (const parentCategory of parentCategories) {
    //     const index = updatedPermissions.indexOf(parentCategory);
    //     if (index !== -1) {
    //       updatedPermissions.splice(index, 1);
    //     }
    //   }
    // }
  };

  const findParentCategory = (category_id) => {
    for (const category of categories) {
      if (category.sections) {
        for (const section of category.sections) {
          if (section.category_id.includes(category_id)) {
            return category.category_id;
          }
        }
      }
    }
    return null;
  };

  const findChildCategories = (category_id) => {
    const childCategories = [];
    for (const category of categories) {
      if (category.category_id.includes(category_id)) {
        if (category.sections) {
          for (const sections of category.sections) {
            childCategories.push(sections.category_id);
          }
          return childCategories;
        }
      }
    }
    return [];
  };

  const renderCategory = (category) => {
    const hasSections = category.sections && category.sections.length > 0;

    return (
      <div key={category.category_id} className="permission-pop-up">
        <div className="category-row">
          <div className="category-name checkbox-column">
            <input
              type="checkbox"
              checked={checkedPermissions.includes(category.category_id)}
              onChange={() => handleCheckboxChange(category.category_id)}
            />
          </div>
          <div className="category-id id-column">{category.category_id}</div>
          <div className="category-name name-column">{category.name}</div>
          <div className="category-subcategory subcategory-column"></div>
          {/* {hasSections && ( */}
          <div className="toggle-button">
            <button
              disabled={!hasSections}
              onClick={() => handleToggle(category.category_id)}
            >
              {category.expanded ? "Hide" : "Show"}
            </button>
          </div>
          {/* ) } */}
        </div>
        {category.expanded && hasSections && (
          <div className="category-children">
            {category.sections.map((section) => (
              /* Subcategory Row */
              <div key={section.category_id} className="subcategory">
                <input
                  type="checkbox"
                  checked={checkedPermissions.includes(section.category_id)}
                  onChange={() => handleCheckboxChange(section.category_id)}
                />
                <div className="subcategory-id">{section.category_id}</div>{" "}
                <div className="subcategory-name">{section.name}</div>
                <div className="subcategory-subcategory"></div>
              </div>
            ))}
          </div>
        )}
        {category.subcategories &&
          category.subcategories.map((subcategory) =>
            renderCategory(subcategory)
          )}
      </div>
    );
  };


    const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCategoryPage = categories.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

 const filteredCategories = currentCategoryPage.filter((category) =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="popup">
      <div className="popup-content">
        <h2>{`Manage Permissions for ${user.first_name} ${user.last_name}`}</h2>
  {filteredCategories.length > 0 && (
  
  <div className="categories-container">
  <div className="category-row">
    <div className="category-row category-header">
      <div className="checkbox-header checkbox-column">Permission</div>
      <div className="id-header id-column">ID</div>
      <div className="name-header name-column">Name</div>
      <div className="subcategory-header subcategory-column">
        Subcategory
      </div>
    </div>
  </div>
  {filteredCategories.map((category) => renderCategory(category))}
</div>
                )}

<PaginationComponent
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        totalItems={categories.length}
                        paginate={paginate}
                      />

{/* ======= */}

        <div className="popup-buttons">
          <button className="btn" onClick={handleSavePermissions}>
            Save
          </button>
          <button className="btn" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default PermissionPopup;
