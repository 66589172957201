import React, { useState } from "react";
import apiRoutes from "../apiRoutes";
import { ApiCall } from "../apiCall";
import { useNavigate, useLocation } from "react-router-dom";
import EditableText from "../utils/EditableText";

const SubCategoryList = ({ isAuthenticated, setIsAuthenticated }) => {
  const location = useLocation();
  const data = location.state;
  let sections = data?.sections?.length ? data?.sections : [];
  const [subcategories, setSubCategories] = useState(sections);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortColumn, setSortColumn] = useState("Id");

  const handleSort = (column) => {
    setSortColumn(column);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const sortedCategories = [...subcategories].sort((a, b) => {
    const isAsc = sortOrder === "asc";
    if (sortColumn === "Id") {
      return isAsc
        ? a.category_id - b.category_id
        : b.category_id - a.category_id;
    } else if (sortColumn === "Name") {
      return isAsc
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    }
    return 0;
  });

  const toggleCategoryStatus = async (category_id, disabled) => {
    // Update the user's status on the server
    if (isAuthenticated) {
      let url = `${apiRoutes.Update_Category}?category_id=${category_id}&disabled=${disabled}`;
      let resp = await ApiCall(url, "PUT");
      if (resp) {
        setSubCategories((prevUsers) =>
          prevUsers.map((category) =>
            category.category_id === category_id
              ? { ...category, disabled }
              : category
          )
        );
      }
    }
  };

  //     const handleUrl=()=>{
  // history.pushState('/category-list')
  //     }

  return (
    <div className="container h-100">
      <h2>Sub Category List</h2>
      <div className="d-flex h-100">
        <div className="align-self-start mr-auto">
          {/* <button onClick={() => navigate(-1)}>Go back</button> */}
        </div>
        <div>
          {subcategories?.length > 0 && (
            <table>
              <thead>
                <tr>
                  {/* <th onClick={() => handleSort("Id")}>
                    Id{" "}
                    {sortColumn === "Id" && (
                      <span style={{ fontSize: "25px" }}>
                        {sortOrder === "asc" ? "↑" : "↓"}
                      </span>
                    )}
                  </th> */}

                  <th>Id</th>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {sortedCategories?.map((item) => (
                  <React.Fragment key={item.category_id}>
                    <tr>
                      <td>{item.category_id}</td>
                      {/* <td>{item.name}</td> */}
                      <td>
                      <EditableText
                        initialText={item.name}
                        category_id={item.category_id}
                        setCategories={setSubCategories}
                        categories={subcategories}
                      />
                      </td>
                      <td>
                        <button
                          className="btn"
                          onClick={() =>
                            toggleCategoryStatus(
                              item.category_id,
                              !item.disabled
                            )
                          }
                        >
                          {item.disabled ? "Enable" : "Disable"}
                        </button>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubCategoryList;
