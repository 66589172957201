import React from "react";

const SearchComponent = ({ searchTerm, setSearchTerm ,placeholder}) => {
  return (
    <input
      type="text"
      placeholder={placeholder}
      onChange={(e) => setSearchTerm(e.target.value)}
      value={searchTerm}
    />
  );
};

export default SearchComponent;
