import React, { useState } from "react";
import Dashboard from "./Dashboard";
import { ApiCall } from "../apiCall";
import apiRoutes from "../apiRoutes";
import Sidebar from "./Sidebar";
import short from 'short-uuid';


const Login = ({ setIsAuthenticated }) => {
  const [loggedIn, setLoggedIn] = useState(
    sessionStorage.getItem("isLoggedIn") === "true"
  );
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const generateDeviceId = () => {
    return short.generate().toString();
  };

  const handleLogin = async () => {
    const deviceId = generateDeviceId();

    let reqBody = {
      email: username,
      password,
      device_id: deviceId, 
    };

    try {
      let response = await ApiCall(apiRoutes.Admin_Login, "POST", reqBody);

      if (response?.code === 200) {
        const { token, refresh_token, expiry } = response?.data;
        const loginTime = new Date().getTime();

        // Calculate the expiration time for the access token
        const accessTokenExpirationTime = expiry * 1000; // Convert seconds to milliseconds

        // Encrypt the tokens before storing them in sessionStorage
        // let salt= config.Key;
        // console.log(salt)
        // const encryptedAccessToken = CryptoJS.AES.encrypt(
        //   token,
        //   salt
        // ).toString();
        // const encryptedRefreshToken = CryptoJS.AES.encrypt(
        //   refresh_token,
        //   salt
        // ).toString();

        sessionStorage.setItem("accessToken", token);
        sessionStorage.setItem("refreshToken", refresh_token);
        sessionStorage.setItem("isLoggedIn", "true");
        sessionStorage.setItem(
          "accessTokenExpiresAt",
          loginTime + accessTokenExpirationTime
        );
        sessionStorage.setItem("deviceId",deviceId)
        // Schedule the token refresh before the access token expires
        // scheduleAccessTokenRefresh();
        setIsAuthenticated(true);
        setLoggedIn(true);
      } else {
        setErrorMessage(response?.message ? response.message : response);
        console.error(response?.message);
      }
    } catch (error) {
      // Handle error
      console.error("An error occurred:", error);
    }
  };

  const handleLogout = () => {
    sessionStorage.removeItem("isLoggedIn");
    sessionStorage.removeItem("loginTime");
    setLoggedIn(false);
  };

  const isSessionValid = () => {
    const loginTime = parseInt(sessionStorage.getItem("loginTime"));
    const currentTime = new Date().getTime();
    const oneDayInMillis = 24 * 60 * 60 * 1000; // One day in milliseconds
    return currentTime - loginTime <= oneDayInMillis;
  };

  return (
    <div>
      {loggedIn ? (
        <div>
          
          <button onClick={handleLogout}>Logout</button>
        </div>
      ) : (
        <div>
          <h2>Admin Login</h2>
          {errorMessage && (
            <section
              style={{
                fontSize: "14px",
                color: "#d83f45",
                paddingLeft: "20px",
              }}
            >
              <p> {errorMessage}</p>
            </section>
          )}
          <div>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button className="btn" onClick={handleLogin}>
            Login
          </button>
        </div>
      )}
    </div>
  );
};

export default Login;
