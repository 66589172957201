import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import jaystu_icon from '../icons/jayostu.png'

export const SidebarData = [

  {
    icon:<img src={jaystu_icon} style={{width: "100px",
      height: "80px",paddingTop:"10px",justifyContent: "center"}} alt="React Image" />
  },
  {
    title: "Home",
    path: "/",
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Categories",
    path: "/category-list",
    icon: <IoIcons.IoIosPaper />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },

  {
    title: "Users",
    path: "/user-list",
    icon: <IoIcons.IoIosPaper />,
  },
];
