import React from "react";
import { Link } from "react-router-dom";

const Home = ({ setIsAuthenticated, isAuthenticated }) => {
  return (
    <>
      {isAuthenticated ? (
        <div
          style={{
            padding: "50px",
          }}
        >
          <h2>This is your secure admin area.</h2>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            //   justifyContent: "center",
              flexDirection: "column",
              fontWeight: "bold",
              whiteSpace: "pre-wrap",
              textAlign:"left"
            }}
          >
            <ul>
              <li>
                <Link to="/dashboard">Dashboard Overview</Link>
              </li>{" "}
              <li>
                <a href="#">Settings</a>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <p>Please log in to view Home</p>
      )}
    </>
  );
};

export default Home;
