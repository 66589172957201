import React, { useState, useEffect, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import apiRoutes from "../apiRoutes";
import { ApiCall } from "../apiCall";
import SubCategoryList from "./SubCategoryList";
import { useNavigate } from "react-router-dom";
import SearchComponent from "./Search";

import EditableText from "../utils/EditableText";
import { TfiAlignJustify } from "react-icons/tfi";

const CategoryList = ({ setIsAuthenticated, isAuthenticated }) => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [isShown, setIsShown] = useState(false);
  const [subcategories, setSubCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      fetchData(currentPage, itemsPerPage);
    }
  }, [isAuthenticated, currentPage, itemsPerPage]);

  const fetchData = async (page, limit) => {
    let url = `${apiRoutes.Category_List}`;
    let resp = await ApiCall(url);
    if (resp?.data?.length) {
      setCategories(resp.data);
    }
  };

  const toggleCategoryStatus = async (category_id, disabled) => {
    if (isAuthenticated) {
      let url = `${apiRoutes.Update_Category}?category_id=${category_id}&disabled=${disabled}`;
      let resp = await ApiCall(url, "PUT");
      if (resp) {
        setCategories((prevCategories) =>
          prevCategories.map((category) =>
            category.category_id === category_id
              ? { ...category, disabled }
              : category
          )
        );
      }
    }
  };

  const handleSectionClick = async (category_id) => {
    if (category_id) {
      try {
        const updatedItem = await fetchUpdatedItem(category_id);
        if (updatedItem?.length) {
          navigate("/sub-category-list", {
            state: { sections: updatedItem[0].sections },
          });
        }
      } catch (error) {
        console.error("Error fetching updated sections:", error);
      }
    }
  };

  const fetchUpdatedItem = async (category_id) => {
    try {
      const response = await ApiCall(
        `${apiRoutes.Category_List}&category_id=${category_id}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching updated item:", error);
      throw error;
    }
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedCategories = Array.from(categories);
    const [movedCategory] = reorderedCategories.splice(result.source.index, 1);
    reorderedCategories.splice(result.destination.index, 0, movedCategory);

    setCategories(reorderedCategories);

    try {
      await ApiCall(apiRoutes.Update_CategoryOrder, "POST", {
        newOrder: reorderedCategories.map((category) => category.category_id),
      });
    } catch (error) {
      console.error("Error updating category order:", error);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="CategoryList">
        {isAuthenticated ? (
          <>
            {!isShown ? (
              <div className="container h-100">
                <h2>Category List</h2>
                <div className="d-flex h-100">
                  <div>
                    <div className="user_search">
                      <SearchComponent
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        placeholder="Search by category name..."
                      />
                    </div>
                    {categories?.length > 0 && (
                      <>
                        <table>
                          <CategoryTableHeader />
                          <Droppable droppableId="categories">
                            {(provided) => (
                              <tbody
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {categories.map((item, index) => (
                                  <CategoryTableRow
                                    key={item.category_id}
                                    item={item}
                                    index={index}
                                    toggleCategoryStatus={toggleCategoryStatus}
                                    handleSectionClick={handleSectionClick}
                                    setCategories={setCategories}
                                    categories={categories}
                                  />
                                ))}
                                {provided.placeholder}
                              </tbody>
                            )}
                          </Droppable>
                        </table>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <SubCategoryList sections={subcategories} />
            )}
          </>
        ) : (
          <p>Please log in to view the category list.</p>
        )}
      </div>
    </DragDropContext>
  );
};

export default CategoryList;

const CategoryTableHeader = () => (
  <thead>
    <tr>
      <th>Id</th>
      <th>Name</th>
      <th>Action</th>
      <th>Sub Categories</th>
      <th></th>
    </tr>
  </thead>
);

const CategoryTableRow = ({
  item,
  index,
  toggleCategoryStatus,
  handleSectionClick,
  setCategories,
  categories,
}) => (
  <Draggable
    key={item.category_id}
    draggableId={item.category_id}
    index={index}
  >
    {(provided) => (
      <React.Fragment>
        <tr
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <td style={{ cursor: "default" }}>{item.category_id}</td>
          <td style={{ cursor: "default" }}>
            <EditableText
              initialText={item.name}
              category_id={item.category_id}
              setCategories={setCategories}
              categories={categories}
            />
          </td>
          <td>
            <button
              className="btn"
              onClick={() =>
                toggleCategoryStatus(item.category_id, !item.disabled)
              }
            >
              {item.disabled ? "Enable" : "Disable"}
            </button>
          </td>
          <td>
            <div>
              <button
                className="btn"
                onClick={() => handleSectionClick(item.category_id)}
                disabled={!item?.sections}
              >
                View
              </button>
            </div>
          </td>
          <td style={{ cursor: "pointer" }}>
            <TfiAlignJustify />
          </td>
        </tr>
        {provided.placeholder}
      </React.Fragment>
    )}
  </Draggable>
);
