import React from "react";
import ReactPaginate from "react-paginate";

const PaginationComponent = ({
  currentPage,
  itemsPerPage,
  totalItems,
  paginate,
}) => {
  return (
    <div>
      <ReactPaginate
        pageCount={Math.ceil(totalItems / itemsPerPage)}
        pageRangeDisplayed={2} 
        marginPagesDisplayed={1} 
        onPageChange={(selected) => {
          paginate(selected.selected + 1); 
        }}
        previousLabel={"< Previous"}
        nextLabel={"Next >"}
        containerClassName="pagination"
        activeClassName={"pagination__link--active"}
        previousLinkClassName="pagination__link"
        nextLinkClassName="pagination__link"
        disabledClassName={"pagination__link--disabled"}
        initialPage={currentPage - 1} 
        renderOnZeroPageCount={null}
        breakLabel="..."
      />
    </div>
  );
};

export default PaginationComponent;
