import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import UserList from "./components/UserList";
import CategoryList from "./components/CategoryList";
import Sidebar from "./components/Sidebar";
import { useState } from "react";
import SubCategoryList from "./components/SubCategoryList";
import Home from "./components/Home";
const RouteComponent = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    sessionStorage.getItem("isLoggedIn") === "true"
  );

  return (
    <div>
      <Router>
        <div className="App">
          {isAuthenticated ? (
            <>
              <Sidebar setIsAuthenticated={setIsAuthenticated}/>
              <Routes>
              {/* <Route path='/about-us' element={<AboutUs/>} /> */}
                <Route path="/" element={<Home setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated}/>} />
                <Route path="/user-list" element={<UserList setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />} />
                <Route path="/category-list" element={<CategoryList setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated}/>} />
                <Route path="/sub-category-list" element={<SubCategoryList setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />} />
              </Routes>
            </>
          ) : (
            <header className="App-header">
              <Routes>
                <Route path="/" element={<Login setIsAuthenticated={setIsAuthenticated}/>} />
              </Routes>
            </header>
          )}
        </div>
      </Router>
    </div>
  );
};
export default RouteComponent;
