import apiRoutes from "./apiRoutes";
import { useNavigate } from "react-router-dom";

let isRefreshing = false;
let refreshPromise = null;

export const ApiCall = async (url, method = "GET", body = {}) => {
  try {
    url = apiRoutes.Base_Url + url;
    const accessToken = sessionStorage.getItem("accessToken");

    let options = {
      method,
      headers: { authorization: `Bearer ${accessToken}` },
    };
    if (method === "POST") {
      options.body = JSON.stringify(body);
      options.headers["Content-type"] = "application/json; charset=UTF-8";
    }

    let response = await fetch(url, options);
    let data = await response.json();

    if (data.code === 401 || data.code === 403) {
      if (!isRefreshing) {
        isRefreshing = true;
        const refreshed = await refreshToken();
        isRefreshing = false;

        if (refreshed) {
          const updatedAccessToken = sessionStorage.getItem("accessToken");
          options.headers.authorization = `Bearer ${updatedAccessToken}`;
          response = await fetch(url, options);
          data = await response.json();
        }
      }
    }

    return data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    return error;
  }
};

const refreshToken = async () => {
  if (!refreshPromise) {
    const accessToken = sessionStorage.getItem("accessToken");
    const refreshToken = sessionStorage.getItem("refreshToken");
    const deviceId = sessionStorage.getItem("deviceId");

    if (!accessToken || !refreshToken) {
      return false;
    }

    const reqData = {
      "refresh-token": refreshToken,
      device_id: deviceId,
    };
    refreshPromise = refreshTokenApiCall(
      apiRoutes.Admin_Token_Refresh,
      reqData
    );

    refreshPromise
      .then((resp) => {
        if (resp.code === 200) {
          sessionStorage.setItem("accessToken", resp?.data?.token);
          sessionStorage.setItem("refreshToken", resp?.data?.refresh_token);
          sessionStorage.setItem("isLoggedIn", "true");
        } else {
          const navigate = useNavigate();
          sessionStorage.clear();
          navigate("/");
        }
      })
      .catch((err) => {
        sessionStorage.clear();
      })
      .finally(() => {
        refreshPromise = null;
      });
  }

  return refreshPromise;
};

const refreshTokenApiCall = async (url, reqData) => {
  url = apiRoutes.Base_Url + url;
  let options = {
    method: "POST",
    body: JSON.stringify(reqData),
    headers: {
      "Content-Type": "application/json",
    },
  };

  let response = await fetch(url, options);
  let data = await response.json();
  return data;
};
