import { useState, useEffect, useRef } from "react";
import { ApiCall } from "../apiCall";
import apiRoutes from "../apiRoutes";

const EditableText = ({
  initialText,
  category_id,
  setCategories,
  categories,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialText);
  const [originalText, setOriginalText] = useState(initialText);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setOriginalText(initialText);
  }, [initialText]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsConfirmationOpen(true);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleBlur = () => {
    if (!inputRef.current || !inputRef.current.contains(document.activeElement)) {
      setIsConfirmationOpen(true);
    }
  };

  const handleConfirm = async () => {
    if (isApiCallInProgress) {
      return;
    }

    setIsApiCallInProgress(true);

    let url = `${apiRoutes.Update_Category}?category_id=${category_id}&name=${text}`;

    try {
      let resp = await ApiCall(url, "PUT");
      if (resp) {
        setCategories((prevCategories) =>
          prevCategories.map((category) =>
            category.category_id === category_id
              ? { ...category, text }
              : category
          )
        );
        setOriginalText(text);
      } else {
        setText(originalText);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsEditing(false);
      setIsConfirmationOpen(false);
      setIsApiCallInProgress(false);
    }
  };

  const handleCancel = () => {
    setText(originalText);
    setIsEditing(false);
    setIsConfirmationOpen(false);
  };

  return (
    <div onDoubleClick={handleDoubleClick}>
      {isEditing ? (
        <div>
          <input
            type="text"
            value={text}
            onChange={handleChange}
            onBlur={handleBlur}
            ref={inputRef}
            disabled={isApiCallInProgress} // Disable input during API call
          />
          {isConfirmationOpen && (
            <div className="confirmation-modal">
              <p>Do you want to save changes?</p>
              <button onClick={handleConfirm} disabled={isApiCallInProgress}>
                Yes
              </button>
              <button onClick={handleCancel} disabled={isApiCallInProgress}>
                No
              </button>
            </div>
          )}
        </div>
      ) : (
        <span>{text}</span>
      )}
    </div>
  );
};

export default EditableText;

