let apiRoutes={};
apiRoutes.Base_Url=process.env.REACT_APP_BASE_URL||'https://api.jayostu.com';
apiRoutes.User_List='/user/list';
apiRoutes.Update_User='/user';
apiRoutes.Category_List='/category?lan=en';
apiRoutes.Admin_Login='/admin/login';
apiRoutes.Update_Category='/category';
apiRoutes.Permission_List='/permission/list';
apiRoutes.Create_Permission='/permission';
apiRoutes.Admin_Token_Refresh='/admin/token-refresh';
apiRoutes.Update_CategoryOrder='/update/category-order'


module.exports = apiRoutes;