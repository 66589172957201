import React, { useState, useEffect } from "react";
import { ApiCall } from "../apiCall";
import apiRoutes from "../apiRoutes";
import { useNavigate } from "react-router-dom";
import PermissionPopup from "./Permissionpopup";
import SearchComponent from "./Search";
import PaginationComponent from "./Pagination";

const UserList = ({setIsAuthenticated,isAuthenticated}) => {
  const navigate = useNavigate();
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [permissionData, setPermissionData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(7); // Number of items per page
  const [isChecked, setIsChecked] = useState(false);

  const handlePermissionButtonClick = async (user) => {
    let url = `${apiRoutes.Permission_List}?email=${user?.user_id}`;
    let resp = await ApiCall(url);
    if (resp?.data?.length) {
      setPermissionData(resp.data);
    } else {
      setPermissionData([]);
    }
    setCurrentUser(user);
    setPopUpVisible(true);
  };

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUserPage = users.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const filteredUsers = currentUserPage.filter((user) =>
    user.user_id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleClosePopUp = () => {
    setPopUpVisible(false);
    setCurrentUser(false);
  };

  const checkHandler = async () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      let url = `${apiRoutes.Permission_List}`;
      let resp = await ApiCall(url);
      if (resp?.data?.length) {
        const uniqueArray = [];
        users.forEach((ele1) => {
          resp.data.forEach(
            (ele2) => ele1?.user_id === ele2?.user_id && uniqueArray.push(ele1)
          );
        });
        setUsers(uniqueArray);
      }
    } else {
      let resp = await ApiCall(apiRoutes.User_List);
      if (resp?.data?.list?.length) {
        setUsers(resp.data.list);
      }
    }
  };

  const handleUpdatePermissions = async (updatedPermissions) => {
    let reqData = {
      user_id: currentUser?.user_id,
      category_ids: updatedPermissions,
    };
    let resp = await ApiCall(apiRoutes.Create_Permission, "POST", reqData);
    if (!resp?.data) {
      console.log(resp);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      async function fetchData() {
        let resp = await ApiCall(apiRoutes.User_List);
        if (resp?.data?.list?.length) {
           setUsers(resp.data.list);
        }
      }
      fetchData();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      async function fetchData() {
        let resp = await ApiCall(apiRoutes.Category_List);
        if (resp?.data?.length) {
          // resp.data.shift();
          setCategoryData(resp.data);
        }
      }
      fetchData();
    }
  }, [isAuthenticated]);

  const toggleUserStatus = async (id, disabled) => {
    // Update the user's status on the server
    if (isAuthenticated) {
      let url = `${apiRoutes.Update_User}/${id}?disabled=${disabled}`;
      let resp = await ApiCall(url, "PUT");
      if (resp) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === id ? { ...user, disabled } : user
          )
        );
      }
    }
  };

  const toggleUserPublishStatus = async (id, is_publisher) => {
    // Update the user's status on the server
    if (isAuthenticated) {
      let url = `${apiRoutes.Update_User}/${id}?is_publisher=${is_publisher}`;
      let resp = await ApiCall(url, "PUT");
      if (resp) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === id ? { ...user, is_publisher } : user
          )
        );
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {isAuthenticated ? (
        <>
          {!popUpVisible && !currentUser ? (
            <>
              <div>
                <h2>User List</h2>
                <div className="user_search">
                <SearchComponent
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  placeholder="Search by email..."
                />
                <Checkbox checkHandler={checkHandler} isChecked={isChecked} />
                </div>
                {filteredUsers.length > 0 && (
                  <>
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Action</th>
                        <th>Publisher</th>
                        <th>User Permission</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredUsers.map((item, i) => (
                        <React.Fragment key={item.id}>
                          <tr key={i}>
                            <td style={{ textTransform: "capitalize" }}>
                              {item.first_name}
                            </td>
                            <td>{item.user_id}</td>
                            <td>
                              <button
                                className="btn"
                                onClick={() =>
                                  toggleUserStatus(item.id, !item.disabled)
                                }
                              >
                                {item.disabled ? "Enable" : "Disable"}
                              </button>
                            </td>
                            <td>
                              <button
                                className="btn"
                                onClick={() =>
                                  toggleUserPublishStatus(item.id, !item.is_publisher)
                                }
                              >
                                {!item.is_publisher ? "Enable" : "Disable"}
                              </button>
                            </td>
                            <td>
                              <button
                                className="btn"
                                onClick={() =>
                                  handlePermissionButtonClick(item)
                                }
                              >
                                Show Permissions
                              </button>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                           <PaginationComponent
                           currentPage={currentPage}
                           itemsPerPage={itemsPerPage}
                           totalItems={users.length}
                           paginate={paginate}
                         />
                         </>
                )}
              </div>
            </>
          ) : (
            <PermissionPopup
              user={currentUser}
              categoriesData={categoryData}
              existingPermissions={permissionData[0]?.category_ids || []}
              onClose={handleClosePopUp}
              onUpdatePermissions={handleUpdatePermissions}
            />
          )}
        </>
      ) : (
        <p>Please log in to view the user list.</p>
      )}
    </div>
  );
};

const Checkbox = ({ isChecked, checkHandler }) => {
  return (
    <div>
      <input
        type="checkbox"
        id="checkbox"
        checked={isChecked}
        onChange={checkHandler}
      />
      <label htmlFor="checkbox">Editable User List </label>
    </div>
  );
};
export default UserList;
